import React from 'react';
import { Link, graphql } from "gatsby"

const DataFact = ({node}) => {
  return(
  <li>
    <Link to={node.slug}>
      <h2>{node.name}</h2>
    </Link>
  </li>
  )
}

const DataFacts = ({data}) => (
  <ul>
    {
      data.allContentfulDataFact.edges.map(
        (edge) => <DataFact key={edge.node.id} node={edge.node}/>
      )
    }
  </ul>
 
)

export default DataFacts;

export const pageQuery = graphql`
  query MyQuery {
    allContentfulDataFact {
      edges {
        node {
          name
          id
          externalSourceUrl
          slug
        }
      }
    }  
  }
`